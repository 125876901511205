<script setup lang='ts'>
import { timeFrom } from '@qctsw/utils'
import { APP_ROUTER } from '@qctsw/common'
import type { PrecinctComplainPageResponse } from '@/server/types'

const props = withDefaults(defineProps<{
  list: PrecinctComplainPageResponse<2>
}>(), {
  list: () => [],
})
</script>

<template>
  <table w-full block>
    <thead class="block w-full">
      <tr class="bg-blue-5 text-white font-bold rounded-t-md flex py-sm">
        <td class="text-center w-32 flex-shrink-0">
          投诉宗数
        </td>
        <td class="text-center flex-grow">
          每日最新汽车投诉标题
        </td>
        <td class="text-center w-32 flex-shrink-0">
          最新投诉时间
        </td>
      </tr>
    </thead>
    <tbody class="block w-full border-1 border-gray-2 rounded-b-md">
      <template v-if="props.list && props.list.length">
        <tr v-for="v, i in props.list" :key="i" class="flex items-center py-sm border-b-1 border-b-slate-1">
          <td class="text-center w-32 flex-shrink-0">
            {{ v.complainCounts }}宗
          </td>
          <td class="text-center truncate flex-grow min-w-0 hover:text-orange-6">
            <NuxtLink :to="APP_ROUTER.collectiveDetail(v.collectiveId)">
              {{ `${v.title}` }}
            </NuxtLink>
          </td>
          <td class="text-center w-32 flex-shrink-0">
            <time :datetime="v.lastComplainTime">{{ timeFrom(v.lastComplainTime) }}</time>
          </td>
        </tr>
      </template>
      <template v-else>
        <BasicEmpty />
      </template>
    </tbody>
  </table>
</template>

<style lang='scss' scoped>

</style>
