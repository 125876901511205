<script setup lang='ts'>
import { timeFormat } from '@qctsw/utils'
import { logicHomeWeekRedBlack } from '@/logic/home/weekRedBlack'

const {
  state,
  data,
  getWeekRedBlack,
} = logicHomeWeekRedBlack()

await getWeekRedBlack()
</script>

<template>
  <div>
    <BasicTitle title="每周红黑榜" class="mb-2.5" />
    <UtilLoadState :state="state" :reload-handler="getWeekRedBlack">
      <RedBlackContainer
        v-if="data"
        :data="data"
        :day-params="{
          weekEndTime: timeFormat(data.endTime, 'YYYY-MM-DD'),
          weekStartTime: timeFormat(data.startTime, 'YYYY-MM-DD'),
        }"
      />
      <BasicEmpty v-else />
    </UtilLoadState>
  </div>
</template>

<style lang='scss' scoped>

</style>
