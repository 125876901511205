import type { PaginationFn } from '@/composables/usePagination'
import { usePagination } from '@/composables/usePagination'
import type { PrecinctComplainPageResponse } from '@/server/types'
import { getPrecinctComplainPageApi } from '@/server'

// 1最新投诉/2集体投诉/3多次投诉/4企业回复/5小型车/6紧凑型车/7中型车/8中大型车/9SUV/10新能源/11其他
export const complaintType = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11] as const
export const complaintTypeTabs = ['最新投诉', '集体投诉', '多次投诉', '企业回复', '小型车', '紧凑型车', '中型车', '中大型车', 'SUV', '新能源', '其他车型']
export function logicComplaintTabs() {
  const activeTab = ref(0)
  const complaintTabs = ref<{
    name: string
    list: PrecinctComplainPageResponse<typeof complaintType[number]>
    precinctCode: typeof complaintType[number]
    pagination: PaginationFn
  }[]>([])

  function initTabs() {
    complaintTabs.value = complaintTypeTabs.map((name, index) => {
      const precinctCode = complaintType[index]
      return {
        name,
        list: [] as PrecinctComplainPageResponse<typeof precinctCode>,
        precinctCode,
        pagination: usePagination({
          key: `COMPLAINT_TABS_${precinctCode}`,
          type: 'up',
          request: params => getPrecinctComplainPageApi(precinctCode, params),
        }),
      }
    })
  }

  async function getComplaintTabs(tab?: number) {
    if (tab !== undefined)
      activeTab.value = tab

    const currentPagination = complaintTabs.value[activeTab.value].pagination
    const { list } = await currentPagination.loadPaginationData() || {}
    if (!list)
      return
    complaintTabs.value[activeTab.value].list.push(...list)
  }

  initTabs()
  return {
    activeTab,
    complaintTabs,
    getComplaintTabs,
  }
}
