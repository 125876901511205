<script setup lang='ts'>
import { APP_ROUTER } from '@qctsw/common'
import type { PrecinctComplainPageResponse } from '@/server/types'

const props = withDefaults(defineProps<{
  list: PrecinctComplainPageResponse<4>
}>(), {
  list: () => [],
})
</script>

<template>
  <table w-full block>
    <thead class="block w-full">
      <tr class="bg-blue-5 text-white font-bold rounded-t-md flex py-sm">
        <td class="flex-shrink-0 px-3 w-32 text-center">
          投诉编号
        </td>
        <td class="text-center flex-shrink-0 w-50 px-3.75">
          厂家
        </td>
        <td class="text-center flex-grow min-w-0">
          回复内容
        </td>
      </tr>
    </thead>
    <tbody class="block w-full border-1 border-gray-2 rounded-b-md">
      <template v-if="props.list && props.list.length">
        <tr
          v-for="v, i in props.list" :key="i"
          class="flex items-center py-sm border-b-1 border-b-slate-1  hover:text-orange-6"
        >
          <td class="flex-shrink-0 px-3 w-32 text-center">
            <NuxtLink :to="APP_ROUTER.complaintDetail(v.complainId)" class="hover:text-orange-6">
              QT{{ v.complainId }}
            </NuxtLink>
            <UtilQrcode :to="`/complaint/detail/${v.complainId}`" class="text-blue-5 ml-0.5" />
          </td>
          <td class="text-center flex-shrink-0 w-50 line-clamp-2 px-3.75">
            {{ v.merchantName }}
          </td>
          <td class="text-center flex-grow min-w-0 pr-3.75 line-clamp-2 hover:text-orange-6">
            <NuxtLink :to="APP_ROUTER.complaintDetail(v.complainId)">
              {{ v.disposeContent }}
            </NuxtLink>
          </td>
        </tr>
      </template>
      <template v-else>
        <BasicEmpty />
      </template>
    </tbody>
  </table>
</template>

<style lang='scss' scoped>

</style>
