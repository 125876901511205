<script setup lang='ts'>
import { isClient } from '@qctsw/utils'
import { APP_ROUTER } from '@qctsw/common'
import ComplaintTabCollective from './ComplaintTabCollective.vue'
import ComplaintTab from './ComplaintTab.vue'
import ComplaintTabMulti from './ComplaintTabMulti.vue'
import ComplaintTabReply from './ComplaintTabReply.vue'
import { logicComplaintTabs } from '@/logic/home/complaintTabs'

function getComponent(type: number) {
  switch (type + 1) {
    case 2:
      return ComplaintTabCollective
    case 3:
      return ComplaintTabMulti
    case 4:
      return ComplaintTabReply
    default:
      return ComplaintTab
  }
}
const {
  activeTab,
  complaintTabs,
  getComplaintTabs,
} = logicComplaintTabs()

// 加载三次后跳转到列表
const router = useRouter()
const isGoListPage = computed(() => {
  const currentTabPages = complaintTabs.value[activeTab.value].pagination.pagination

  if (!currentTabPages.page)
    return false
  return complaintTabs.value[activeTab.value].list.length >= 4 * currentTabPages.pageSize
    || currentTabPages.page >= currentTabPages.pageCount
})

function loadMore() {
  if (!isGoListPage.value) {
    getComplaintTabs()
    return
  }
  router.push(APP_ROUTER.complaintList())
}

let stops: any[] | null = null
function activeHandler() {
  deactivateHandler()
  stops = [
    watch(activeTab, (nv) => {
      if (complaintTabs.value[nv].list.length)
        return
      getComplaintTabs()
    }),
  ]
}
function deactivateHandler() {
  stops?.forEach(v => v?.())
  stops = null
}

if (isClient) {
  onMounted(activeHandler)
  onUnmounted(deactivateHandler)
  onActivated(activeHandler)
  onDeactivated(deactivateHandler)
}

await getComplaintTabs()
</script>

<template>
  <div class="complaint pt-6">
    <BasicTitle class="mb-2.5" title="投诉">
      <template #after>
        <NTabs v-model:value="activeTab" type="line" animated trigger="click" px-1>
          <NTab
            v-for="v, i in complaintTabs" :key="v.name" :name="i"
            :style="{ '--n-tab-padding': '0 0 0.25rem 0', '--n-tab-font-weight-active': 'bold' }"
          >
            {{ v.name }}
          </NTab>
        </NTabs>
      </template>
    </BasicTitle>
    <div class="flex">
      <div class="flex-grow min-w-205">
        <UtilLoadState
          type="up"
          :length="(complaintTabs[activeTab].list as any)?.length"
          :state="complaintTabs[activeTab].pagination.pagination.status"
          :reload-handler="() => getComplaintTabs()"
        >
          <component
            :is="getComponent(activeTab)"
            :list="(complaintTabs[activeTab].list as any)"
          />
          <BasicLoadButton
            class="bg-slate-2"
            :state="complaintTabs[activeTab].pagination.pagination.status"
            @load="() => loadMore()"
          >
            <span v-if="isGoListPage">
              <span>查看更多</span>
              <i class="i-ic:round-keyboard-double-arrow-right vertical-icon text-3.5" />
            </span>
            <span v-else> 加载更多</span>
          </BasicLoadButton>
        </UtilLoadState>
        <slot name="after" />
      </div>
      <slot name="right" />
    </div>
  </div>
</template>

<style lang='scss' scoped>

</style>
