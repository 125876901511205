<script setup lang='ts'>
import { timeFrom } from '@qctsw/utils'
import { APP_ROUTER } from '@qctsw/common'
import type { PrecinctComplainPageResponse } from '@/server/types'
import { getComplaintStatus } from '@/shared/function'

const props = withDefaults(defineProps<{
  list: PrecinctComplainPageResponse<1>
}>(), {
  list: () => [],
})
</script>

<template>
  <table w-full block>
    <thead class="block w-full">
      <tr class="bg-blue-5 text-white font-bold rounded-t-md flex py-sm">
        <td class="text-center w-32 flex-shrink-0">
          投诉编号
        </td>
        <td class="text-center flex-grow">
          每日最新汽车投诉标题
        </td>
        <td class="text-center w-34 flex-shrink-0">
          状态
        </td>
        <td class="text-center w-34 flex-shrink-0">
          投诉时间
        </td>
      </tr>
    </thead>
    <tbody class="block w-full border-1 border-gray-2 rounded-b-md">
      <template v-if="list && list.length">
        <tr v-for="v, i in props.list" :key="i" class="flex items-center py-sm border-b-1 border-b-slate-1">
          <td class="w-32 flex-shrink-0 text-3.5 flex items-center justify-center">
            <NuxtLink :to="APP_ROUTER.complaintDetail(v.complainId)" class="hover:text-orange-6">
              QT{{ v.complainId }}
            </NuxtLink>
            <UtilQrcode
              :to="APP_ROUTER.complaintDetail(v.complainId)"
              class="text-blue-5 ml-0.5"
            />
          </td>
          <td class="flex items-center justify-center flex-grow min-w-0">
            <NuxtLink
              :to="APP_ROUTER.complaintDetail(v.complainId)"
              class="truncate inline-block hover:text-orange-6"
            >
              {{ v.title }}
            </NuxtLink>
            <UtilHasMedia :is-img="v.existImg" :is-video="v.existVideo" :is-audio="v.existAudio" />
          </td>
          <td class="text-center text-3.25 w-34 flex-shrink-0" :class="[`status-${v.complainStatusCode}`]">
            {{ getComplaintStatus(v.complainStatusCode) }}
          </td>
          <td class="text-center text-3.25 w-34 flex-shrink-0">
            <time :datetime="v.createTime">{{ timeFrom(v.createTime) }}</time>
          </td>
        </tr>
      </template>
      <template v-else>
        <BasicEmpty />
      </template>
    </tbody>
  </table>
</template>

<style lang='scss' scoped>
@import url('@/assets/styles/status.scss');
</style>
